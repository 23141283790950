@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// @import "../styles/components/example.scss";
.icon-color {
  color: #999999;
}
.s-color-grey {
  color: rgba(0, 0, 0, 0.67);
}
.s-title {
  font-size: 3rem;
}
.s-subtitle-1 {
  font-size: 1.125rem;
}
.s-matte-green {
  color: #9ebf9f;
}
.s-quiz-title {
  font-size: 1.6rem;
}
.s-quiz-subheading {
  font-size: 1.125rem;
}
